import './App.css';
import Home from './Home';
import { useEffect } from 'react';
import { setLanguage, getLanguage } from '../TextManager';

export default function App() {

  useEffect(() => {
    switch (window.location.pathname) {
      case '/en': 
        setLanguage('en');
        break;
      case '/fr': 
        setLanguage('fr');
        break;
      default: 
        break;
    }
  }, []); // Empty dependency array ensures this runs only once when the component mounts.

  return (
    <div className="app">
      <img className="app-background" src="/images/invocaster-background_2000px.jpg" alt="Background"/>
      <Home/>

      <div className="app-footer">
        <div className="footer-left">
          <div className="languages">
            <div className={"language" + (getLanguage() === "en" ? " selected" : "")} id="english-language">
            {
              getLanguage() === "en" ?
              <img src="/images/flag-eng.png" alt="English"/> :
              <a href={`${window.location.protocol}//${window.location.host}`} onClick={() => setLanguage('en')}>
                <img src="/images/flag-eng.png" alt="English"/>
              </a>
            }
            </div>
            <div className={"language" + (getLanguage() === "fr" ? " selected" : "")} id="french-language">
            {
              getLanguage() === "fr" ?
              <img src="/images/flag-fr.png" alt="Français"/> :
              <a href={`${window.location.protocol}//${window.location.host}`} onClick={() => setLanguage('fr')}>
                <img src="/images/flag-fr.png" alt="Français"/>
              </a>
            }
            </div>
          </div>
        </div>
        <div className="footer-right">
          <div className="socials">
            <div className="socials-list">
              <div className="social-link">
                <a href="https://www.facebook.com/invocaster" target="_blank" rel="noreferrer">
                  <img src="/images/facebook-icon.png" alt="Facebook link"/>
                </a>
              </div>
              <div className="social-link">
                <a href="https://www.instagram.com/invocaster" target="_blank" rel="noreferrer">
                  <img src="/images/instagram-icon.png" alt="Facebook link"/>
                </a>
              </div>
              <div className="social-link">
                <a href="https://x.com/Invocaster" target="_blank" rel="noreferrer">
                  <img src="/images/x-icon.png" alt="Facebook link"/>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  );
}
