import './Separator.css';

export default function Separator({ children }) {

  return (
    <div className="main-separator">
      <img className="main-separator-pattern left-pattern" src="/images/pattern-left.png" alt="Separator left pattern"/>
      <div className="main-separator-text">{ children }</div>
      <img className="main-separator-pattern right-pattern" src="/images/pattern-left.png" alt="Separator left pattern"/>
    </div>
  );
}
