import './Lightbox.css';

export default function Lightbox ({ className, open, onClose, style, children }) {
	
  return (
    <div className={`${className} lightbox-container${open !== true ? " invisible" : ""}`}>
      <div className="lightbox-inner" onClick={onClose}>
        <div className="lightbox" style={style} onClick={(e) => e.stopPropagation()}>
          {children}
        </div>
      </div>
    </div>
  );
};